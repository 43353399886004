import {
  AddDisplayLocationDto,
  DisplayEntity,
  DisplayMetadata,
} from '@admefy/domain';
import { inject, Injectable } from '@angular/core';
import { catchError, EMPTY, map, Observable, switchMap } from 'rxjs';
import { RestService } from '../admefy-cli/admefy-cli.service';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  private readonly metadataService = inject(MetadataService);
  private readonly rest = inject(RestService);

  public newQRTokenDisplay({
    socketId,
    providerId,
    ownerId,
    position,
  }: {
    socketId: string;
    providerId?: string;
    ownerId?: string;
    position: GeolocationPosition | null;
  }): Observable<{ token: string }> {
    return this.metadataService.getGeoData().pipe(
      switchMap((metadata) => {
        const data: {
          socketId: string;
          providerId?: string;
          ownerId?: string;
          metadata: DisplayMetadata;
        } = {
          ownerId,
          socketId,
          providerId,
          metadata: {
            city: metadata.city,
            country: metadata.country_code,
            countryName: metadata.country,
            continentCode: metadata.continent,
            currentIp: metadata.ip,
            ip: metadata.ip,
            latitude: metadata.latitude,
            longitude: metadata.longitude,
            timezone: metadata.timezone,
            location: {
              type: 'Point',
              coordinates: [
                Number(position?.coords.longitude || metadata.longitude) || 0,
                Number(position?.coords.latitude || metadata.latitude) || 0,
              ],
            },
          },
        };

        return this.rest.post(`/display/qr`, data).pipe(
          map((res) => {
            return res.body?.data;
          }),
        );
      }),
    );
  }

  getQRUrlImg(linkCode: string): string {
    return `${this.rest.apiUrl}/display/qr?content=${linkCode}`;
  }

  getDisplayToken(id: string): Observable<string> {
    return this.rest.get(`/display/${id}`).pipe(
      map((res) => {
        return res.body?.data;
      }),
    );
  }

  getDisplay(): Observable<DisplayEntity> {
    return this.rest.get(`/display`).pipe(
      map((res) => {
        return res.body?.data;
      }),
    );
  }

  saveSocketId(socketId: string): Observable<void> {
    return this.rest.post(`/display/socket`, { socketId }).pipe(
      map(() => {
        return;
      }),
    );
  }

  addDisplayLocations(body: AddDisplayLocationDto): Observable<void> {
    return this.rest.post(`/display/locations`, body).pipe(
      catchError(() => EMPTY),
      map(() => {
        return;
      }),
    );
  }

  getCustomScreenSaver(): Observable<{
    url: string;
    duration: number;
    subscription: any;
  } | null> {
    return this.rest.get(`/display/screensaver`).pipe(
      map((res) => {
        return res.body?.data;
      }),
    );
  }
}
